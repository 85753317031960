p {
    -ms-word-break: keep-all;
    word-break: keep-all;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
    -ms-hyphens: auto;
}

h2 {
    font-size: 19px;
}

a, a:hover, a:focus {
    text-decoration: none;
}

@media all and (max-width: 767px) {
    body.keyboard {
        height: calc(100% + 500px); /* add padding for keyboard */
    }
}

.top-menu {
    position: absolute;
    max-width: 1170px;
    width: 100%;
}

.top-menu .affix {
    top: 0;
    z-index: 1;
    min-width: 1140px;
}

.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
    display: none !important;
}

#header {
    z-index: 100;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    min-height: 50px;
    /* border-bottom: 1px solid #f6f6f6; */
}

#header.nav-up {
    transition: 0.8s;
    top: 0px;
}

#header.nav-down {
    transition: 0.4s;
    top: 0;
}

#header .navbar-default {
    background: white;
    border-bottom: 1px #EDEDEF solid;
}

#header .navbar-default .navbar-toggle {
    margin-top: 10px;
    margin-right: 5px;
    padding: 7px;
}

#header .navbar-default .navbar-nav > li > a.sub-nav-linked:active,
#header .navbar-default .navbar-nav > li > a.sub-nav-linked:hover,
#header .navbar-default .navbar-nav > li > a.sub-nav-linked:focus,
#header .navbar-default .navbar-nav > .open .dropdown-menu > li > a.sub-nav-linked:active,
#header .navbar-default .navbar-nav > .open .dropdown-menu > li > a.sub-nav-linked:hover,
#header .navbar-default .navbar-nav > .open .dropdown-menu > li > a.sub-nav-linked:focus {
    background: #CC1100;
}

#header .navbar,
#header .navbar-default .navbar-brand,
#header .navbar-default .navbar-nav > li > a span,
.page-header .page-header-with-icon {
    height: 52px !important;
    z-index: 100 !important;
}

#header .navbar-default .navbar-brand {
    display: table-cell;
    float: left;
    padding-top: 9px;
    height: 50px;
    vertical-align: middle;
}

#header .navbar-default .search-top {
    margin-left: 5px;
}

#header .input-sm {
    font-size: 14px;
}

#header .navbar .navbar-form, #header .navbar .navbar-form-catering {
    right: 5px;
    margin-left: 5px;
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 8px;
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}

#main {
    margin-top: 50px;
}

.btn-tg-grey-light {
    background-color: #edefed;
    color: #333333;
}

.bg-tg-grey {
    background-color: #dddddd;
    color: #333333;

}

.btn-tg-grey-light.active {
    background-color: #CC1100;
    color: #FFFFFF;
}

.btn-tg-grey.active {
    background-color: #CC1100;
    color: #FFFFFF;
}

#main-content {
    padding: 0 0 80px 0;
    background-color: /* #f6f6f6*/ #FFFFFF;
}

#main-content .container {
    padding: 15px 15px 0;
    margin-right: auto;
    margin-left: auto;
}

@media all and (max-width: 767px) {
    #top-bar-mobile {
        /*margin-top: 36px;*/
    }

    #header .navbar .navbar-form, #header .navbar .navbar-form-catering {
        position: absolute;
        left: 60px;
        padding-right: 0;
        padding-left: 0;
        width: 65%;
    }

    .navbar-nav .dropdown-menu a {
        color: #fff !important;
    }

    .navbar-collapse {
        max-height: 600px;
    }

    .visible-xs-inline {
        display: inline !important;
    }

    #frame-stats {
        height: 600px;
    }

}

@media all and (max-width: 991px) {
    #mobile-menu-extra {
        border-bottom: 1px solid #f0f0f0;
        z-index: 100;
        width: 100%;
    }

    #header {
        z-index: 200;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        min-height: 86px;
    }

    #mobile-menu-extra button {
        border: 0;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
    }

    #main {
        margin-top: 86px;
    }
}

@media all and (min-width: 768px) and (max-width: 1199px) {
    #header .navbar .navbar-form {
        width: 380px;
    }

    #header .navbar .navbar-form-catering {
        width: 240px;
    }
}

@media all and (min-width: 1200px) {
    #header .navbar .navbar-form {
        width: 550px;
    }

    #header .navbar .navbar-form-catering {
        width: 400px;
    }
}

.affix-tabs {
    z-index: 1;
    background: white;
}

.tabbable .tab-content {
    border: 0 solid white;
    background-color: white;
}

.dropdown-menu {
    z-index: 1000;
}

.carousel .carousel-inner > .item {
    z-index: 998;
}

.very-very-light-bg {
    background-color: #fafafa;
}

.info-light-bg {
    background-color: #b7c2cc;
}

.catering-admin-menu > li .dropdown-menu li,
.user-admin-menu > li .dropdown-menu li {
    display: block !important;
    margin: 0;
}

.catering-admin-menu > li .dropdown-menu,
.user-admin-menu > li .dropdown-menu {
    margin-top: 5px;
    right: 50%;
    margin-right: -108px;
    width: 216px;
    border: 1px solid #EDEDEF;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 3px;
    background-color: white;
    color: #333333;
}

.catering-admin-menu li > .dropdown-menu a,
.catering-admin-menu li > .dropdown-menu span.title,
.user-admin-menu li > .dropdown-menu a {
    display: block;
    color: #333333;
    font-weight: 500;
}

.catering-admin-menu li > .dropdown-menu a:hover,
.user-admin-menu li > .dropdown-menu a:hover {
    background-color: #CC1100;
    color: white;
}

.nav .open > a, .nav .open > a:hover, .nav .open > a:focus {
    background-color: #eeeeee;
    color: #333333;
    border: 0;
}


.catering-admin-menu li > .dropdown-menu li.title {
    display: block;
    padding-left: 20px;
    color: #ccc;
}

.catering-admin-menu li > .dropdown-menu li.indent a {
    padding-left: 40px;
}

.main-menu li > .dropdown-menu li a {
    text-align: left !important;
}

.catering-public-menu > .btn {
    font-size: 14px;
    width: 83px;
    padding: 8px 5px;
}

.catering-public-menu > .btn.home {
    width: 50px;
}

.catering-public-menu > a:hover,
.catering-public-menu > a:focus {
    background-color: #ffffff;
    color: #CC1100;
}

.catering-public-menu > .btn-active {
    border: 1px solid transparent;
    border-bottom: 4px solid #CC1100;
    background-color: white;
    font-weight: 600 !important;
}

@media (max-width: 768px) {
    .catering-public-menu > .btn.home {
        width: 83px;
    }

    .catering-public-menu > .btn {
        font-size: 14px;
        margin: 2px;
        padding: 8px 5px;
    }
}

.nav-stacked {
    padding: 0 5px;
}

.nav-pills.staff li a {
    background: white;
    border-bottom: solid 1px rgb(237, 237, 239);
}

.nav-pills.staff li a:hover {
    background-color: rgb(237, 237, 239);
}

.nav-stacked li {
    margin: 0 0 5px 0 !important;
}

.nav-stacked.staff li {
    margin: 0 0 0 0 !important;
}

.nav-stacked li a,
.nav-stacked li.active a {
    padding: 10px 15px !important;
}

.nav-stacked.staff li a,
.nav-stacked.staff li.active a {
    padding: 10px 25px !important;
}


.inline {
    display: inline-block;
}

ul.unstyled {
    margin: 8px;
    padding: 0;
}

ul.unstyled li {
    margin: 0;
    padding: 0;
    list-style: none;
}

.block {
    display: block;
}

@media only screen and (max-width: 746px) {
    .block-xs {
        display: block;
    }
}

.bold {
    font-weight: bold !important;
}

.cursor-pointer {
    cursor: pointer;
}

hr {
    margin: 30px auto;
}

.text-center {
    text-align: center;
}

.tooltip-inner {
    background-color: #333 !important;
}

.tooltip {
    pointer-events: none;
}

.offer-price {
    font-weight: bold;
    font-size: 28px;
}

.offer-discount {
    font-size: 12px;
}

.price-discount-bundle {
    color: #999;
    font-size: 72%;
}

.search-results .offer-search-result,
.search-results .catering-search-result {
    margin: 0;
}

.search-type-row h2 {
    margin-top: 14px;
    margin-bottom: 11px;
}

.search-type-row select {
    margin-top: 9px;
    height: 33px;
}

.search-type-row .results-count {
    line-height: 48px;
}

.search-type-button {
    text-align: center;
    line-height: 40px;
    cursor: pointer;
}

.search-type-button:hover {
    background-color: #f0f0f0;
    text-decoration: none;
}

.search-type-button a {
    color: #000;
    text-decoration: none;
    font-weight: normal;
}

.search-type-button a:hover {
    text-decoration: none;
}

.search-type-button.active a {
    text-decoration: none;
    font-weight: bold;
}

.search-type-button.active {
    border-bottom: 3px solid #CC1100;
    background-color: #fff;
}

.search-type-button.active a {
    color: #CC1100;
}

.search-type-button h2 {
    line-height: 22px;
}

.search-filters-row {
    padding: 6px 0;
    border-bottom: 1px solid #f0f0f0;
}

.search-filters-row .filter-name {
    display: block;
    line-height: 30px;
}

.search-filters-row input,
.search-filters-row select {
    height: 30px;
}

.search-filters-row .btn.btn-light:hover {
    background-color: #f0f0f0;
}

#search-filters-full {
    display: none;
}

.search-filters-row .btn.btn-sm {
    padding-right: 9px;
    padding-left: 9px;
}

.promo-time {
    border: 1px solid #aeb0b4;
}

.text-underline {
    text-decoration: underline;
}

#carousel-overlay {
    position: absolute;
    top: 10%;
    left: 0;
    z-index: 999;
    width: 100%;
}

#carousel-overlay > .centered {
    margin: 0 auto;
    max-width: 800px;
}

.homepage .carousel .item {
    max-height: 400px;
}

.claim span {
    display: inline-block;
    padding: 15px 30px;
    background-color: black;
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    font-weight: 500;
    font-size: 48px;
    font-family: "Roboto", serif;
    line-height: 60px;
}

.search-option {
    padding: 12px 12px;
    border-width: 2px;
    font-size: 18px;
    line-height: 22px;
}

ul.horizontal li {
    display: inline-block;
    margin-right: 8px;
}

p.airy {
    line-height: 2em !important;
}

p.airy-md {
    line-height: 1.8em !important;
}

.a-50 {
    opacity: 0.5;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    filter: alpha(opacity=50);
    -moz-opacity: 0.5;
    -khtml-opacity: 0.5;
}

.a-30 {
    opacity: 0.3;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
    filter: alpha(opacity=30);
    -moz-opacity: 0.3;
    -khtml-opacity: 0.3;
}

.a-70 {
    opacity: 0.7;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
    filter: alpha(opacity=70);
    -moz-opacity: 0.7;
    -khtml-opacity: 0.7;
}

.a-90 {
    opacity: 0.9;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
    filter: alpha(opacity=90);
    -moz-opacity: 0.9;
    -khtml-opacity: 0.9;
}

.a-100 {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    -moz-opacity: 1;
    -khtml-opacity: 1;
}

div.info-flash {
    padding: 8px;
    border-bottom: 1px solid #f0f0f0;
    background-color: #f6f6f6;
}

div.info-flash h1, div.info-flash h2, div.info-flash h3, div.info-flash h4, div.info-flash h5 {

}

div.info-flash p {
    font-weight: 500;
}

div.info-flash-success {
    border-top: 3px solid #66cc66;
}

div.info-flash-success i {
    color: #66cc66;
}

div.info-flash-warning {
    border-top: 3px solid #f0ad4e;
}

div.info-flash-warning i {
    color: #f0ad4e;
}

div.info-flash-danger {
    border-top: 3px solid #d9534f;
}

div.info-flash-danger i {
    color: #d9534f;
}

div.info-flash-info {
    border-top: 3px solid #428bca;
}

div.info-flash-info i {
    color: #428bca;
}

.phone-lg {
    display: block;
    font-size: 120%;
}

.menu-sm {
    display: none;
}

.menu-sm ul li, .menu-sm ul li + li {
    margin-bottom: 5px !important;
    margin-left: 0 !important;
}

.menu-sm li hr {
    margin: 12px 0;
}

.row .form-group + div + .col-md-4 {
    margin-bottom: 12px;
}

@media only screen and (max-width: 992px) {
    .input-error-sm {
        border-color: #d9534f;
    }
}

@media (max-width: 480px) {
    .nav-tabs > li {
        width: 125px;
    }
}

.inline-block {
    display: inline-block;
}

.search-type-selector {
    color: #CC1100 !important;
    font-weight: bold;
}

.dashboard-user h3 {
    /*text-transform: uppercase;
    font-size: 100%;*/
}

.dashboard-user .dashboard-sidebar h3 {
    margin: 0;
}

.dashboard-user .offers-list h3 {
    margin-bottom: 0;
}

.dashboard-user table tr td {
    border: 1px solid #ddd;
}

.dashboard-user .news .line {
    margin-bottom: 8px;
}

.dashboard-user .panel-heading {
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: bold;
}

a.search-tab-selector:hover {
    border-bottom: 3px solid #CC1100;
    text-decoration: none;
}

.search-tab-selector {
    border-bottom: 3px solid #f0f0f0;
    text-decoration: none;
}

.other-search-tab-selector {
    text-decoration: none;
}

.search-tab-selector.active {
    border-bottom: 3px solid #CC1100;
    /*border-bottom: 3px solid #f0f0f0;*/
    color: #CC1100 !important;
    text-decoration: none;
    font-size: 100%;
}

a.dashboard-main-selector:hover {
    border-bottom: 3px solid #CC1100;
    text-decoration: none;
}

.dashboard-main-selector {
    border-bottom: 3px solid #f0f0f0;
    text-decoration: none;
    font-size: 80%;
}

.dashboard-main-selector.active {
    border-bottom: 3px solid #CC1100;
    /*border-bottom: 3px solid #f0f0f0;*/
    color: #CC1100 !important;
    text-decoration: none;
    font-size: 100%;

}

.catering-profile-info {
    margin-top: 20px;
}

.catering-profile-info h4 {
    margin-bottom: 0 !important;
    text-transform: uppercase;
    font-size: 12px;
}

.catering-profile h4 {
    margin-top: 24px;
    text-transform: uppercase;
}

.offer-profile-info h4 {
    margin-bottom: 0 !important;
    text-transform: uppercase;
    font-size: 12px;
}

.overlay-black {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    display: none;
    width: 100%;
    height: 100%;
    background-color: #000;
    text-align: center;
    opacity: 0.8;
}

.catering-index .panel-heading {
    background-color: #fafafa;
}

.search-bar-xs {
    position: fixed;
    top: inherit;
    bottom: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 50px;
    background-color: #000;
    line-height: 50px;
}

.search-bar-xs .switch {
    display: inline-block;
    font-size: 130%;
}

.search-bar-xs .switch.active {
    background-color: #CC1100;
}

.search-bar-xs a {
    color: #fff !important;
}

.search-bar-xs .switch.active a {
    color: #fff !important;
}

.search-bar-xs .close-filters-block {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 50px;
    background-color: #000;
    line-height: 50px;
}

.search-bar-xs-active {
    position: fixed;
    top: 50px;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    background-color: rgba(255, 255, 255, 1);
}

.search-bar-xs-active .filters {
    overflow-y: auto;
    padding-bottom: 50px;
    height: 100%;
}

.search-bar-xs-active .filters hr {
    margin: 16px 0;
}

.noscroll {
    overflow: hidden;
}

.tabs-cateringa .active a {
    color: #CC1100 !important;
}

.icon-tg-search-off.fa-icon-2x {
    font-size: 200% !important;
}

.catering-search-result .btn-light.disabled {
    color: #000000;
}

.no-decoration, .no-decoration:hover, .no-decoration:focus, .no-decoration:active {
    text-decoration: none;
}


.popover {
    max-width: 350px;
    width: 350px;
}

.btn-square {
    width: 45px;
    height: 45px;
}

.icon-share {
    color: #999999;
    text-decoration: none;
    cursor: pointer;
}

.icon-share:hover {
    color: #CC1100;

    text-decoration: none;
}

.twitter-share {
    color: #999999;
    text-decoration: none;
    cursor: pointer;
}

.twitter-share:hover {
    color: #00aced;
    text-decoration: none;
}

.twitter-text-color {
    color: #00aced;
}

.facebook-share {
    color: #999999;
    text-decoration: none;
    cursor: pointer;
}

.facebook-share:hover {
    color: #3b5998;
    text-decoration: none;
}

.facebook-share img {
    width: 14px;
}

.facebook-text-color {
    color: #3b5998;
}

.btn-facebook, .btn-facebook:hover, .btn-facebook:focus, .btn-facebook:active, .btn-facebook:after {
    background: #3b5998;
    color: #ffffff;
}


.google-text-color {
    color: #4285f4;
}

.btn-google, .btn-google:hover, .btn-google:focus, .btn-google:active, .btn-google:after {
    background: #4285f4;
    color: #ffffff;
}

.btn-apple, .btn-apple:hover, .btn-apple:focus, .btn-apple:active, .btn-apple:after {
    background: #000000;
    color: #ffffff;
}

.share {
    text-decoration: none;
    font-size: 14px;
    cursor: pointer;
}

.share:hover {
    text-decoration: none;
}

.whatsapp-share {
    text-decoration: none;
    color: #999999;
    cursor: pointer;
}

.whatsapp-share:hover {
    color: #24a526;
    text-decoration: none;
}

.whatsapp-share img {
    width: 14px;
}

.whatsapp-text-color {
    color: #24a526;
}

.btn-whatsapp {
    background: #24a526;
    color: #ffffff;
}

#filter-bar .form-control {
    height: 33px;
}

#filter-bar .btn {
    padding: 0;
    font-size: 14px;
    height: 25px;
}

.display-none {
    display: none;
}

.progress-label-left {
    float: left;
    margin-right: 1em;
}

.progress-label-right {
    float: right;
    margin-left: 1em;
}

.tabbable .nav-tabs > li > a {
    font-size: 14px;
    background-color: #EDEDEF;

}

.tabbable .nav-tabs > li.active > a, .tabbable .nav-tabs > li.active > a:hover, .tabbable .nav-tabs > li.active > a:focus {
    border: 1px solid transparent;
    border-bottom: 4px solid #CC1100;
    background-color: white;
    font-weight: 600 !important;
}

.admin-dashboard .btn-extra-lg {
    display: inline-block;
    width: 90px;
    height: 90px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    background-color: #CC1100;
    color: white;
    font-size: 36px;
}

.admin-dashboard {
    text-align: center;
}

.v-align-center {
    display: inline-block;
    float: none;
    vertical-align: middle;
}

.v-align-top {
    display: inline-block;
    float: none;
    vertical-align: top;
}

.catering-admin-menu .btn-light.btn-bordered,
.catering-admin-menu .btn-contrast,
.catering-admin-menu .btn-success {
    border-width: 1px;
}

.admin-menu ul > li {
    border-bottom: 1px solid #EDEDEF;
    margin-right: 0;
}

.admin-menu ul > li:hover {
    border-bottom: 1px solid #CC1100;
}

.user-admin-menu .btn-light.btn-bordered,
.user-admin-menu .btn-contrast,
.user-admin-menu .btn-success {

    border-width: 1px;
}

.no-radius {
    -webkit-border-radius: 0 !important;
    -moz-border-radius: 0 !important;
    border-radius: 0 !important;
}

.panel.panel-circle-big-contrast .panel-icon {
    background-color: transparent;
}

div.portfoliocard a.nostyle {
    margin: 0;
    padding: 0;
    color: inherit;
    text-decoration: none;
}

div.portfoliocard div.footer {
    padding-right: 10px;
    width: 100%;
    /*height: 20px;*/
    background: #CC1100 center center;
    color: white;
    vertical-align: middle;
    font-size: 14px;
    text-align: right;
}

div.portfoliocard div.logo {

    position: absolute;
    bottom: 20px;
    left: 9%;
    margin-top: 10px;
    color: rgba(30, 30, 30, 1);
    font-weight: 300;
    font-size: 16px;

}

div.portfoliocard {
    position: relative;
    z-index: 100;
    overflow: hidden;
    margin: 0 auto 10%;
    padding: 0;
    width: 100%;
    height: 450px;
    border: 1px solid rgba(0, 0, 0, 0.7);
    border-radius: 6px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0 -1px 3px rgba(0, 0, 0, 0.1),
    0 2px 6px rgba(0, 0, 0, 0.5);
}

div.portfoliocard div.coverphoto {
    z-index: 99;
    width: 100%;
    height: 150px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    background-size: cover;
    background: no-repeat center center;
    box-shadow: inset 0 3px 20px rgba(255, 255, 255, 0.3),
    1px 0 2px rgba(255, 255, 255, 0.7);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;

}

div.portfoliocard div.left_col, div.portfoliocard div.right_col {
    float: left;
    height: 340px;

}

div.portfoliocard div.left_col {
    padding-top: 74px;
    width: 40%;
    text-align: center;
}

div.portfoliocard div.right_col {
    margin-left: -1px;
    width: 60%;
    border-left: 1px solid rgba(230, 230, 230, 1);
    border-bottom-right-radius: 5px;
    background: rgba(245, 245, 245, 1);
    box-shadow: inset 0 1px 1px rgba(255, 255, 255, 0.7);
}

div.portfoliocard div.profile_picture {
    position: absolute;
    top: 125px;
    left: 10px;
    padding: 7px;
    width: 110px;
    height: 110px;
    border: 4px solid rgba(255, 255, 255, 1);
    border-radius: 100%;
    background: rgba(255, 255, 255, 1);
    background-size: cover;
}

div.portfoliocard div.right_col h2.name {
    margin: 20px 10px 0 10px;
    padding: 0;
    color: #CC1100;
    font-weight: 500;
    font-size: 22px;
}

div.portfoliocard div.right_col h3.location {
    margin: -5px 10px 10px 10px;
    padding: 0;
    color: #CC1100;
    font-weight: 300;
    font-size: 15px;
}

div.portfoliocard div.right_col h3.locale {
    margin: 1px 0 0 30px;
    padding: 0;
    color: #CC1100;
    font-weight: 300;
    font-size: 12px;
}

div.portfoliocard ul.contact_information {
    margin-top: 0;
    padding-left: 10px;
    list-style-type: none;
}

div.portfoliocard ul.contact_information li {
    padding: 5px 0;
    width: 100%;
    height: 25px;
    background-size: 18px 18px;
    background-repeat: no-repeat;
    color: #CC1100;
    font-weight: 500;
    font-size: 15px;
    /*
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 1px rgba(255, 255, 255, 0.6);
    text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.8);
    */
    line-height: 25px;
    cursor: default;
}

@media (max-width: 768px) {
    div.portfoliocard ul.contact_information li {
        padding: 0;
        width: 100%;
        height: 100%;
        font-size: 12px;
    }
}

div.portfoliocard ul.contact_information li:hover:before {
    opacity: 1;
}

div.portfoliocard ul.contact_information li.website:before {

    background-image: url('#');
}

div.portfoliocard ul.contact_information li.phone:before {
    background-image: url('#');
}

div.portfoliocard ul.contact_information li.resume:before {
    background-image: url('#');
}

div.portfoliocard div.followers, div.portfoliocard div.following {
    margin: 5px 10px 0 10px;
    color: #1e1e1e;
    font-weight: 300;
    font-style: italic;
    font-size: 16px;
}

div.portfoliocard div.follow_count {
    color: #8c8c8c;
    font-weight: 400;
    font-size: 25px;
}

.checkboxList {
    overflow-y: scroll;
    height: 300px;
}

.panel.panel-circle-big-contrast {
    border: 0;
}

.button-search-xs {
    padding-right: 0;
    padding-left: 0;
    border-width: 1px
}

.center-block {
    margin-right: auto;
    margin-left: auto;
}

.right-block {
    margin-right: 0;
    margin-left: auto;
}

.left-block {
    margin-right: auto;
    margin-left: 0;
}

.text-justify {
    text-align: justify;
}

.text-uppercase {
    text-transform: uppercase;
}

.text-wrap {
    overflow-wrap: break-word;
}

@media (max-width: 767px) {
    .text-center-xs {
        text-align: center;
    }
}

.pos-rel {
    position: relative !important;
}

.pos-abs {
    position: absolute !important;
}

.pos-sta {
    position: static !important;
}

.img-rounded {
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.img-rounded-half {
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}

@media (min-width: 768px) {
    .img-responsive-sm {
        display: block;
        max-width: 100%;
        height: auto;
    }
}

@media (max-width: 768px) {
    .img-responsive-xs {
        display: block;
        max-width: 100%;
        height: auto;
    }
}

.btn-full-width {
    display: block;
    width: 100%;
}

.isDisabled {
    cursor: not-allowed;
    opacity: 0.5;
}

a.isDisabled {
    display: inline-block; /* For IE11/ MS Edge bug */
    pointer-events: none;
    text-decoration: none;
}


.icon-wrap {
    display: inline-block;
    width: 30px;
    height: 30px;
    text-align: center;
    font-size: 1.3em;
}

.cc-cookies {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 99999;
    padding: 0.5em 5%;
    width: 100%;
    background: #565656;
    background: rgba(86, 86, 86, 0.95);
    color: #fff;
    text-align: center;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.35);
    font-weight: 700;
    font-size: 13px;
}

::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color: #888888;
}

:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: #888888;
    opacity: 1;
}

::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: #888888;
    opacity: 1;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #888888;
}

.form-control:-moz-placeholder {
    color: #888888;
}

.form-control::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: #888888;
    opacity: 1;
}

.form-control:-ms-input-placeholder {
    color: #888888;
}

.form-control::-webkit-input-placeholder {
    color: #888888;
}

#schedule-edit select {
    padding-right: 0;
    padding-left: 0;
}

.btn-tg {
    border-color: #CC1100;
    background: #CC1100 none;
    color: white;
}

.btn-tg:hover, .btn-tg:focus {
    border-color: #b11a17;
    background-color: #b11a17;
    color: white;
}

.btn-tg:active, .btn-tg.active {
    border-color: #d21e1b;
    background-color: #d21e1b;
    color: white;
}

.btn-tg.disabled, .btn-tg[disabled] {
    border-color: #D21E1B;
    background-color: #d21e1b;
    color: white;
}

.btn-tg.disabled:hover, .btn-tg.disabled:active, .btn-tg.disabled:focus, .btn-tg.disabled.active, .btn-tg[disabled]:hover, .btn-tg[disabled]:active, .btn-tg[disabled]:focus, .btn-tg[disabled].active {
    border-color: #d21e1b;
    background-color: #d21e1b;
    color: #ffffff;
}

.btn-tg.btn-bordered {
    border-color: #d21e1b;
    color: #d21e1b;
}

.btn-tg.btn-bordered:hover, .btn-tg.btn-bordered:focus {
    background-color: rgba(210, 30, 27, 0.1);
}

.btn-tg.btn-bordered.disabled, .btn-tg.btn-bordered.disabled:hover, .btn-tg.btn-bordered.disabled:active, .btn-tg.btn-bordered.disabled:focus, .btn-tg.btn-bordered.disabled.active, .btn-tg.btn-bordered[disabled], .btn-tg.btn-bordered[disabled]:hover, .btn-tg.btn-bordered[disabled]:active, .btn-tg.btn-bordered[disabled]:focus, .btn-tg.btn-bordered[disabled].active, fieldset[disabled] .btn-tg.btn-bordered, fieldset[disabled] .btn-tg.btn-bordered:hover, fieldset[disabled] .btn-tg.btn-bordered:active, fieldset[disabled] .btn-tg.btn-bordered:focus, fieldset[disabled] .btn-tg.btn-bordered.active {
    border-color: #d21e1b;
    color: #d21e1b;
}

button:focus {
    outline: 0 !important;
}

#ui-datepicker-div {
    top: 50px !important;
}

.cc_container .cc_btn, .cc_container .cc_btn:visited {
    background-color: #CC1100;
    color: #ffffff;
}

.input-group .validationMessage {
    display: table-caption;
    caption-side: bottom;
}

.input-error {
    border-color: #d9534f !important;
}

.textarea-invite {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 8px;
    width: 100%;
    font-weight: 500;
    height: 310px;
    border: 1px solid #f3f3f3; /* Safari/Chrome, other WebKit */
    line-height: 1.4em; /* Opera/IE 8+ */
}

.border-contrast {
    border-color: #CC1100;
}

.border-primary {
    border-color: #999999;
}

@media (min-width: 768px) {
    .main-content-background {
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    #validate-activate .border-contrast {
        border: 1px solid #CC1100;

    }

    .results-item {
        height: 250px;
    }
}

@media (max-width: 768px) {
    .main-content-background {
        background-color: #ffffff;
    }

    #validate-activate .border-contrast {
        border: 0;
    }

    .results-item {
        height: 100%;
    }

    .svg-responsive {
        width: 100%;
        height: auto;
    }
}

.filter-user-mobile .affix {
    top: 50px;
    right: 0;
    left: 0;
    z-index: 9999 !important;
    padding: 0;
    width: 100%;
}

.modal-dialog {
    background-color: white;
    border-radius: 5px;
}

.modal-open {
    overflow: hidden;
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
}


#modalBookingCart.modal {
    overflow-x: hidden;
    overflow-y: auto;
    position: fixed;
    width: 100%;
}

#modalFanCard .modal-dialog {
    background-color: rgba(0, 0, 0, 0);
}

@media screen and (min-width: 768px) {
    .modal-lg {
        right: auto;
        z-index: 10000;
        width: 70%;
    }

    .modal-md {
        right: auto;
        width: 50%;
    }

    .modal-sm {
        right: auto;
        width: 30%;
    }
}

.trovacigusto-fixed-bottom {
    position: fixed !important;
    bottom: 0 !important;
    left: 0 !important;
    z-index: 10 !important;
    width: 100% !important;
}

.trovacigusto-fixed-top {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    z-index: 10 !important;
    width: 100% !important;
}

.account-menu, .trovacigusto-modal, .section-menu {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    -webkit-transition: left 0s, top 0s, opacity .2s cubic-bezier(0.4, .01, .165, .99);
    -moz-transition: left 0s, top 0s, opacity .2s cubic-bezier(0.4, .01, .165, .99);
    -ms-transition: left 0s, top 0s, opacity .2s cubic-bezier(0.4, .01, .165, .99);
    -o-transition: left 0s, top 0s, opacity .2s cubic-bezier(0.4, .01, .165, .99);
    transition: left 0s, top 0s, opacity .2s cubic-bezier(0.4, .01, .165, .99);
}

.video-modal {
    max-height: 62%;
    width: 62%;
    height: auto;

}

@media screen and (max-width: 768px) {

    .video-modal {
        max-height: 100%;
        width: 100%;
        height: auto;
    }

    .pac-container {
        /*
        position: fixed !important;
        top: 50px !important;
        */
        left: 0 !important;
        width: 100% !important;
    }

    .search-tab-selector {
        border-bottom: 3px solid #f0f0f0;
        text-decoration: none;
        font-size: 60%;
    }

    a.search-tab-selector:hover {
        border-bottom: 3px solid #CC1100;
        text-decoration: none;
    }

    .search-tab-selector.active {
        border-bottom: 3px solid #CC1100;
        /*border-bottom: 3px solid #f0f0f0;*/
        color: #CC1100 !important;
        text-decoration: none;
        font-weight: bold;
    }
}

@media screen and (max-width: 435px) {

    .search-tab-selector {
        border-bottom: 3px solid #f0f0f0;
        text-decoration: none;
        font-size: 80%;
    }

    a.search-tab-selector:hover {
        border-bottom: 3px solid #CC1100;
        text-decoration: none;
    }

    .search-tab-selector.active {
        border-bottom: 3px solid #CC1100;
        /*border-bottom: 3px solid #f0f0f0;*/
        color: #CC1100 !important;
        text-decoration: none;
        font-weight: bold;
    }
}

@media only screen and (min-width: 436px) and (max-width: 992px) {
    .search-tab-selector {
        border-bottom: 3px solid #f0f0f0;
        text-decoration: none;
        font-size: 80%;
    }

    a.search-tab-selector:hover {
        border-bottom: 3px solid #CC1100;
        text-decoration: none;
    }

    .search-tab-selector.active {
        border-bottom: 3px solid #CC1100;
        /*border-bottom: 3px solid #f0f0f0;*/
        color: #CC1100 !important;
        text-decoration: none;
        font-weight: bold;
    }
}

.slider .slider-handle {
    background: #676f7a linear-gradient(to bottom, #676f7a, #676f7a);
    opacity: 1;
}

.slider.slider-horizontal .slider-tick, .slider.slider-horizontal .slider-handle {
    margin-top: -2px;
    margin-left: -10px;
}

.slider.slider-horizontal {
    width: 152px;
    height: 20px;
}

.slider-handle {
    width: 15px;
    height: 15px;
}

#user-dem-filter input[type=checkbox] {
    display: none;
}

#user-dem-filter input[type=checkbox] + label:before {
    display: inline-block;
    padding-top: 35px;
    width: 30px;
    height: 30px;
    font-size: 24px;
    font-family: FontAwesome, sans-serif;
}

#user-dem-filter input[type=checkbox] + label:before {
    content: "\f096";
}

#user-dem-filter input[type=checkbox] + label:before {
    letter-spacing: 10px;
}

#user-dem-filter input[type=checkbox]:checked + label:before {
    color: #7cc67c;
    content: "\f046";
}

#gusty-spinner div {
    z-index: 1000;
    margin: auto;
    width: 70px;
    height: 70px;
    background-size: cover;
    animation-iteration-count: infinite;

}

#gusty-loader {
    background: url("/assets/trovacigusto/logo/svg/logo-loader.svg");
    animation: cw 1000ms;
    animation-timing-function: ease-in-out;
}


#form-editor-geo .loader {
    position: relative;
    top: 50%;
    z-index: 100;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.6);
}


.dashboard-user .loader {
    position: fixed;
    top: 50px;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.6);
}

.dashboard-user .loader div {
    position: fixed;
    top: 30%;
    left: 50%;
    margin-left: -35px;
    width: 70px;
}

.container .loader {
    position: fixed;
    top: 50px;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.6);
}

.container .loader div {
    position: relative;
    top: 50%;

}


.modal-body .loader {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.6);
}

.modal-body .loader div {
    position: relative;
    top: 30%;

}


.search-results .loader {
    position: relative;
}

.search-results .loader div {
    position: relative;
    top: 30%;
}

@keyframes cw {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }

}

.rwd-video {
    position: relative;
    overflow: hidden;
    padding-top: 30px;
    padding-bottom: 56.25%;
    height: 0;
}

.rwd-video iframe,
.rwd-video object,
.rwd-video embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.hr-text {
    position: relative;
    height: 1.5em;
    outline: 0;
    border: 0;
    color: black;
    text-align: center;
    line-height: 1em;
    opacity: .5;
}

.hr-text:before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    background: -webkit-linear-gradient(left, transparent, #000, transparent);
    background: linear-gradient(to right, transparent, #000, transparent);
    content: '';
}

.hr-text:after {
    position: relative;
    display: inline-block;
    padding: 0 .5em;
    background-color: #fcfcfa;
    color: black;
    content: attr(data-content);
    line-height: 1.5em;
}

@media screen and (min-width: 768px) {

    .card {
        position: relative;
        display: inline-block;
        margin: 1rem;
        width: 100%;
        height: 100%;
        border-radius: 2px;
        background: rgba(0, 0, 0, 0.5);
    }

    .card-1 {
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
    }

    .card-1:hover {
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    }

    .card-2 {
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    }

    .card-3 {
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    }

    .card-4 {
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    }

    .card-5 {
        box-shadow: 0 19px 38px rgba(0, 0, 0, 0.30), 0 15px 12px rgba(0, 0, 0, 0.22);
    }
}

.fan-heart {
    padding-left: 2px;
    white-space: nowrap;
}

.no-separable {
    white-space: nowrap;
}

.text-nowrap {
    white-space: nowrap !important;
}

@media (max-width: 991px) {
    .text-wrap-xs {
        white-space: normal !important;
    }
}

.panel.panel-image .panel-body {
    padding-bottom: 5px;
}

.disable-div {
    opacity: 0.5;
    pointer-events: none;
}

.equal {
    overflow: hidden;

}

.row-eq-height {
    display: table-row;
    height: 100%;
}

@media (min-width: 992px) {
    .row-eq-height .h100 {
        display: table-cell;
        float: none;
    }
}


.btn-datatables {
    width: 40px;
    padding: 4px 10px !important;
}

@media (min-width: 768px) {
    .vertical-align {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
    }
}

/**
se associato ad un row allinea verticalemnte le colonne
 */
.v-align-flex {
    display: flex;
    align-items: center;
    flex-direction: row;
}

.select-xxs {
    width: 70px;
}

.select-xs {
    width: 90px;
}

.select-sm {
    width: 110px;
}

.select-md {
    width: 168px;
}

.select-lg {
    width: 200px;
}

table.dataTable.dtr-column > tbody > tr > td.control,
table.dataTable.dtr-column > tbody > tr > th.control {
    position: relative;
    cursor: pointer;
    height: 50px;
    width: 50px;
}

table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > td.dtr-control:before,
table.dataTable.dtr-inline.collapsed > tbody > tr[role="row"] > th.dtr-control:before {
    top: 50%;
    left: 5px;
    height: 14px;
    width: 14px;
    margin-top: -9px;
    display: block;
    position: absolute;
    color: #CC1100;
    border-radius: 0;
    box-shadow: none;
    box-sizing: content-box;
    text-align: center;
    text-indent: 0 !important;
    font-family: "FontAwesome", serif;
    line-height: 14px;
    font-size: 20px;
    content: '\f0fe';
    background-color: transparent;
}


table.dataTable.dtr-column > tbody > tr > td.control:before,
table.dataTable.dtr-column > tbody > tr > th.control:before {

    color: #CC1100;
    border-radius: 0;
    box-shadow: none;
    box-sizing: content-box;
    text-align: center;
    text-indent: 0 !important;
    font-family: "FontAwesome", serif;
    line-height: 14px;
    font-size: 20px;
    content: '\f0fe';
    background-color: transparent;
}

table.dataTable.dtr-column > tbody > tr.parent td.control:before,
table.dataTable.dtr-column > tbody > tr.parent th.control:before {
    font-family: "FontAwesome", serif;
    content: '\f146';
    background-color: #FFF;
}

table.dataTable tbody td {
    vertical-align: middle;
    /*text-align: center;*/
}

.dataTables_wrapper .dataTables_processing {
    height: 100px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
    color: white !important;
    border: 0;
    background: none;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
    padding: 0;
}

div.dataTables_processing > div:last-child > div {
    display: none;
    position: absolute;
    top: 0;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #CC1100;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.btn-w-xs {
    width: 20px;
    padding: 5px !important;
}

.btn-w-letter {
    width: 40px;
    padding: 5px !important;
}


.btn-w-sm {
    width: 80px;
}

.btn-w-md {
    width: 130px;
}

.btn-w-tg {
    width: 120px;
}

.btn-w-lg {
    width: 200px;
}

.btn-w-xl {
    width: 250px;
}

.btn-w-share {
    padding: 4px 0;
    border-width: 1px;
    font-size: 12px;
    flex: 1 0 30% !important;
}

#cart-service .btn-w-md {
    width: 100px;
    padding: 4px;
}

@media (max-width: 746px) {
    .btn.btn-w-share {
        flex: 1 0 46% !important;
    }

    .btn-w-tg {
        width: 120px;
    }


    .btn-w-md, .btn-w-xl, .btn-w-lg {
        width: auto;
        min-width: 100px;
    }
}

.swal2-modal .swal2-title {
    font-size: 20px;
}

.swal2-popup {
    font-size: 14px !important;
}

.swal2-styled {
    padding: 10px 32px 10px 32px !important;
    margin: 20px 10px 0px 10px !important;
    width: 170px;
    height: 45px;
}

.tooltip-inner {
    max-width: 350px;
}

#search-left {
    padding: 25px 0 0;
    background-color: #FFFFFF;
}

#search-box {
    width: 100%;
}

#search-box .form-control {
    border: 1px solid #EDEDEF;
    border-radius: 0;
    font-size: 14px;
    font-weight: 300;
    height: 30px;
    border-right: 0;
}

.pac-container:after {
    /* Disclaimer: not needed to show 'powered by Google' if also a Google Map is shown */
    height: 0;
    background-image: none !important;
    z-index: 1051 !important;
}

#extra-search {
    border-bottom: 1px solid #EDEDEF;
}

#extra-search button {
    background-color: #CC1100;
    color: white;
    padding: 10px 5px;
    cursor: pointer;
    float: left;
    border: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

#extra-search button:not(:last-child) {
    border-right: none;
}

/* Clear floats (clearfix hack) */
#extra-search:after {
    content: "";
    clear: both;
    display: table;
}

/* Add a background color on hover */
#extra-search button:hover, #extra-search button:focus {
    background-color: #CC1100 !important;
    color: #FFFFFF !important;
}

.btn-filter-off {
    border-color: #353b47;
    color: #353b47;
    background: white;
}

.catering-row {
    display: flex;
    flex-flow: wrap;
    flex-direction: row;
    justify-content: flex-start;
}

.catering-row .right {
    align-self: flex-end;
    text-align: right;
    flex: 1;
}

.circle-badge {
    background: #EDEDEF;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    text-align: center;
    color: black;
    font-size: 12px;
    font-weight: 500;
    display: inline-block;
}

.circle-badge-success {
    background: #66cc66;
    color: white;
}

.circle-badge-light {
    background: #EFEFEF;
    color: #C1C1C1;
}

.swal2-popup .swal2-content {
    font-size: 14px;
    font-weight: 500;
}

#footer p, #footer a {
    color: white;
}

#footer a:hover, #footer a:focus {
    color: #CC1100;
}

.field-icon {
    float: right;
    margin-left: -25px;
    margin-top: -25px;
    position: relative;
    z-index: 2;
}

@media (max-width: 768px) {
    .svg-responsive {
        width: 100%;
        height: auto;
    }
}

.justify-center {
    justify-content: center;
}

/*------------------------------------*\
    Affix Menu.
\*------------------------------------*/
.admin-menu .affix-top {
    position: static
}

.admin-menu .affix {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
}

.catering-profile-info .affix {
    top: 80px;
    z-index: 10;
    width: 263px;
}

@media (max-width: 991px) {
    .catering-profile-info {
        margin-bottom: 40%;
    }

    .catering-profile-info .affix {
        top: unset;
        bottom: 0;
        z-index: 10;
        width: 100%;
        padding-right: 30px;
    }
}

.badge-notify-icon {
    margin-right: -12px !important;
}

.badge-notify {
    display: initial;
    min-width: 10px;
    padding: 1px 5px;
    font-size: 9px;
    font-weight: bold;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: top;
    background-color: #777;
    border-radius: 10px;
    position: relative;
    top: -2px;
}

.badge-notify.select {
    color: #cc1100;
    background: white;
}

.badge-notify.unselect {
    color: white;
    background: #cc1100;
}

.image-delete {
    line-height: 12px;
    padding: 5px 5px;
    position: absolute;
    right: 15px;
    top: 0;
    font-weight: bold;
}

.datepicker.dropdown-menu {
    z-index: 9999 !important;
}

.lg-outer .lg-item {
    background: url("../images/loading.gif") no-repeat scroll center center transparent;
    display: none !important;
}

.user-filter .multiselect-container {
    width: 200px;
}

.dtr-details > tr > td > div.row {
    width: 100% !important;
}

.dtr-details > tr > td.button {
    width: 100% !important;
}

.offer-datepicker {
    height: auto;
}

.offer-datepicker .datepicker-inline,
.offer-datepicker .datepicker-inline table {
    width: 100%;
    padding: 0;
}


.cart-datepicker {
    height: auto;
}

.cart-datepicker .datepicker-inline,
.cart-datepicker .datepicker-inline table {
    width: 100%;
    padding: 0;
    border: 1px solid #dcdcdc;
}

.cart-datepicker .datepicker-inline .today {
    background: #dcdcdc;
}

.cart-datepicker table tr td {
    color: #5cb85c !important;
}

.cart-datepicker table tr td.disabled {
    border: none;
    color: #ddd !important;
    background: none;
}

.cart-datepicker table tr td.active {
    border: none;
    color: #fff !important;
    font-weight: bold;
    background-image: none !important;
    background-color: #5cb85c !important;
}

.cart-datepicker table tr td.new,
.cart-datepicker table tr td.old {
    visibility: hidden;
}

.cart-datepicker .table-condensed thead > tr > th,
.cart-datepicker .table-condensed thead > tr > td,
.cart-datepicker .table-condensed tbody > tr > th,
.cart-datepicker .table-condensed tbody > tr > td,
.cart-datepicker .table-condensed tfoot > tr > th,
.cart-datepicker .table-condensed tfoot > tr > td {
    padding: 2px;
}

.btn-time {
    padding: 2px !important;
    font-weight: normal;
    width: 50px;
    margin: 1px !important;
}


#validate-user-address select {
    height: 50px;
    font-size: 18px;
}

.target:target {
    display: block;
    position: relative;
    top: -120px;
    visibility: hidden;
}

.icon-2x {
    font-size: 1.5em;
    vertical-align: text-top;
}

.swal-modal {
    z-index: 2000;
}

.icon-food_guru:before {
    margin: 0
}

.large-xxs {
    width: 70px;
}

.large-xs {
    width: 90px;
}

.large-sm {
    width: 110px;
}

.large-md {
    width: 168px;
}

.large-lg {
    width: 200px;
}

.progressBar {
    position: relative;
    width: 95%;
    height: 20px;
    margin: 10px auto;
    border-radius: 5px;
    overflow: hidden;
    background-color: #f5f5f5;
}

.progressBar div {
    position: relative;
    height: 100%;
    background-color: #66cc66;
}

.progressBar .progressText {
    position: absolute;
    margin: 0 auto;
    top: 0;
    left: 10px;
    right: 0;
    background-color: transparent;
}

#progress-popularity .progress {
    margin-bottom: 5px;
}

.btn-showmore {
    width: 90%;
    padding: 1px 4px !important;
    font-size: 16px !important;
    overflow-wrap: break-word;
    white-space: normal;
}

.trovacigusto-card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    margin: auto;
    text-align: center;
}

.trovacigusto-card .card-heading {
    padding: 10px 15px;
    height: 70px;
}

.trovacigusto-card .card-body {
    padding: 15px;
    height: 100%;
}

.trovacigusto-card .card-footer {
    padding: 10px 15px;
    background-color: #f5f5f5;
    border-top: 2px solid #ddd;
}

.trovacigusto-card .card-footer.success {
    border-top: 2px solid #66cc66;
}

.trovacigusto-card .card-footer.danger {
    border-top: 2px solid #cc1100;
}

.trovacigusto-card .card-footer.warning {
    border-top: 2px solid #f0ad4e;
}


@media (max-width: 768px) {
    .trovacigusto-card .card-heading {
        padding: 10px 15px;
        height: 100px;
    }
}

.section.research {
    margin-bottom: 15px;
    border: 1px solid #ededef;
    box-shadow: 1px 1px 5px 0 rgba(96, 96, 96, 0.1) !important;
}

.refresh-button-search {
    padding-left: 68px;
}

#search-spinner div {
    z-index: 1000;
    margin: auto;
    width: 70px;
    height: 70px;
    background-size: cover;
}

#search-loader {
    background: url("/assets/trovacigusto/search-loader.gif");
}

.user-full-name-template .address {
    text-transform: capitalize;
    margin-bottom: 0;
    padding-bottom: 10px;
}

.swiper-container {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.swiper-button-next, .swiper-button-prev {
    color: #CC1100 !important;
}

.blinking-contrast {
    animation: blinking-text-contrast 1s infinite;
}

.blinking-contrast-slow {
    animation: blinking-text-contrast 1.5s infinite;
}

@keyframes blinking-text-contrast {
    0% {
        color: #CC1100;
    }
    49% {
        color: #CC1100;
    }
    60% {
        color: transparent;
    }
    99% {
        color: transparent;
    }
    100% {
        color: #CC1100;
    }
}

.blinking-button {
    animation: blinking-bg 1.5s linear infinite;
}

@keyframes blinking-bg {
    50% {
        opacity: 0;
    }
}


.custom-file-input-wrapper {
    position: relative;
}

.custom-file-input-wrapper .custom-file-input-button {
    position: relative;
    overflow: hidden;
}

.custom-file-input-wrapper .custom-file-input-button * {
    cursor: pointer;
}

.custom-file-input-wrapper .custom-file-input-button input[type="file"] {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    cursor: pointer;
    opacity: 0;
}

.label-ads {
    display: inline;
    padding: 0.1em 0.6em 0.1em;
    font-size: 10px;
    font-weight: 500;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    border-radius: 0.25em;
    background-color: #66cc66;
}

/* ----------------------------------------------------------------------
	Icon Box
-------------------------------------------------------------------------*/
.icon {
    margin-bottom: 16px;
}

div.icon-box:before {
    display: none !important;
}

.icon-box {
    margin-bottom: 50px;
    position: relative;
}

.icon-box .icon {
    height: 64px;
    position: absolute;
    width: 64px;
}

.icon-box .icon i {
    font-size: 28px;
    text-align: center;
    line-height: 66px;
    border-radius: 50%;
}

.icon-box .icon a,
.icon-box .icon i {
    width: 100%;
    height: 100%;
    color: #333;
}

.icon-box h3,
.icon-box h5 {
    margin: 0 12px 10px 83px;
    letter-spacing: 0;
    font-size: 16px;
    line-height: 25px;
    font-weight: 700;
}

.icon-box p {
    margin: 0 0 20px 83px;
    font-size: 16px;
    line-height: 28px;
    font-weight: 400;
}

.icon-box-description > p {
    margin-bottom: 20px;
}

.icon-box.effect .icon i {
    z-index: 1;
}

.icon-box.effect .icon i:after {
    pointer-events: none;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    content: '';
    box-sizing: content-box;
}

.icon-box .icon i {
    -webkit-transition: background-color 0.2s, color 0.2s;
    transition: background-color 0.2s, color 0.2s;
}

.icon-box .icon i:after {
    top: -4px;
    left: -4px;
    padding: 4px;
    box-shadow: 0 0 0 3px #333;
    -webkit-transition: -webkit-transform 0.2s, opacity 0.2s;
    -webkit-transform: scale(0.8);
    -moz-transition: -moz-transform 0.2s, opacity 0.2s;
    -moz-transform: scale(0.8);
    -ms-transform: scale(0.8);
    transition: transform 0.2s, opacity 0.2s;
    transform: scale(0.8);
    opacity: 0;
}

.icon-box.effect.dark .icon i:after {
    box-shadow: 0 0 0 3px #CC1100;
}

.icon-box.effect.dark .icon i:hover, .icon-box.effect.dark:hover .icon i {
    background-color: #CC1100;
}

.icon-box.effect .icon i:hover:after, .icon-box.effect:hover .icon i:after {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 1;
}


.image-box .image-box-content p i.fa.fa-play {
    padding-left: 3px;
}

.icon-box.small .icon {
    height: 46px;
    width: 46px;
}

.icon-box.small .icon i {
    font-size: 20px;
    line-height: 47px;
}

.icon-box.small > h3,
.icon-box.small > h5 {
    font-size: 18px;
    margin: 0 12px 4px 60px;
}

.icon-box.small > p {
    margin: 0 0 20px 60px;
    line-height: 1.6;
    font-size: 14px;
}

.icon-box.medium > h3,
.icon-box.medium > h4,
.icon-box.medium > h5 {
    margin: 0 12px 10px 84px;
}

.icon-box.medium > p {
    margin: 0 0 20px 84px;
}

.icon-box.large .icon {
    height: 86px;
    width: 86px;
}

.icon-box.large .icon i {
    font-size: 38px;
    line-height: 87px;
}

.icon-box.large > h3,
.icon-box.large > h5 {
    margin: 0 12px 10px 110px;
    font-size: 24px;
}

.icon-box.large p {
    margin: 0 0 20px 110px;
}

.icon-box.icon-box-right {
    padding: 0;
    text-align: right;
}

.icon-box.icon-box-right > h3,
.icon-box.icon-box-right > h5 {
    margin: 0 84px 0 10px;
}

.icon-box.icon-box-right.medium > p {
    margin: 20px 84px 0 10px;
}

.icon-box.icon-box-right .icon {
    right: 0;
}

.icon-box.icon-box-right p {
    font-size: 14px;
    margin-top: 15px;
}

.icon-box.text-center, .icon-box.center {
    padding: 0;
    text-align: center;
}

.icon-box.text-center h3,
.icon-box.text-center h5, .icon-box.center h3,
.icon-box.center h5 {
    margin: 0 12px 10px 0;
}

.icon-box.text-center p, .icon-box.center p {
    margin: 0 0 20px 0;
}

.icon-box.text-center .icon, .icon-box.center .icon {
    margin: 0 auto 22px;
    position: relative;
}

.icon-box.text-center p, .icon-box.center p {
    font-size: 16px;
    margin-top: 15px;
}

.icon-box.text-center.small .icon, .icon-box.center.small .icon {
    clear: both !important;
    position: relative !important;
}

.icon-box.border {
    border: 0 !important;
}

.icon-box.border .icon {
    border: 3px solid #333;
    text-align: center;
    border-radius: 50%;
    background-color: transparent;
}

.icon-box.border .icon i {
    line-height: 56px;
}

.icon-box.border:hover > .icon i {
    line-height: 62px;
}

.icon-box.border:hover > .icon {
    border: 0;
}

.icon-box.border .icon i {
    background-color: transparent !important;
    color: #333;
}

.icon-box.border.small .icon {
    height: 46px;
    position: absolute;
    top: 5px;
    width: 46px;
}

.icon-box.border.small .icon i {
    font-size: 20px;
    line-height: 42px;
}

.icon-box.border.small:hover > .icon i {
    line-height: 49px;
}

.icon-box.border.large .icon i {
    line-height: 81px;
}

.icon-box.border.large:hover > .icon i {
    line-height: 86px;
}

.icon-box.square.large:hover > .icon i {
    line-height: 86px;
}

.icon-box.square .icon, .icon-box.square.effect .icon i:after,
.icon-box.square .icon i {
    border-radius: 0;
}

.icon-box.square:hover > .icon i {
    line-height: 66px;
}

.icon-box.square.border .icon i {
    line-height: 59px;
}

.icon-box.square.border:hover > .icon i {
    line-height: 64px;
}

.icon-box.square.border.small .icon i {
    line-height: 41px;
}

.icon-box.square.border.small:hover > .icon i {
    line-height: 47px;
}

.icon-box.square.border.large .icon i {
    line-height: 78px;
}

.icon-box.square.border:large:hover > .icon i {
    line-height: 84px;
}

.icon-box.square.small:hover > .icon i {
    line-height: 48px;
}

.icon-box.light .icon i:after {
    box-shadow: 0 0 0 3px #e5e5e5;
}

.icon-box.light .icon i {
    background-color: #e5e5e5;
}

.icon-box.light .icon i {
    color: #808080;
}

.icon-box.light.border .icon {
    border: 3px solid #e5e5e5;
}

.icon-box.light.border.large .icon i {
    line-height: 87px;
}

.icon-box.light.border.large:hover > .icon i {
    line-height: 88px;
}

.icon-box.fancy .icon i {
    background-color: transparent;
    color: #222;
    font-size: 110px;
    opacity: 0.09;
    -webkit-transition: all ease 0.5s;
    transition: all ease 0.5s;
    border-radius: 0;
}

.icon-box.fancy.small > .icon i {
    font-size: 68px;
}

.icon-box.fancy.large .icon i {
    font-size: 120px;
}

.icon-box.fancy.text-center .icon, .icon-box.fancy.center .icon {
    width: auto;
    margin-bottom: 30px;
}

.icon-box.effect.fancy .icon i:after {
    box-shadow: 0 0 0 0 transparent;
}

.icon-box.effect.fancy:hover > .icon i {
    font-size: 110px;
}

.icon-box.effect.fancy.small:hover > .icon i {
    font-size: 76px;
}

.icon-box.clean .icon i {
    background-color: unset;
    color: #333;
    font-size: 50px;
}

.icon-box.clean.small .icon i {
    font-size: 36px;
}

.icon-box.effect.clean .icon i:after {
    box-shadow: 0 0 0 0 transparent;
}

.icon-box.process {
    border: 1px solid #888;
    margin-top: 45px;
    padding: 0 30px 10px;
    border-radius: 4px;
}

.icon-box.process.effect .icon i:hover:after, .icon-box.process.effect:hover .icon i::after {
    opacity: 0;
    border: 0;
}

.icon-box.process .icon {
    margin-top: 10px;
}

.icon-box.process.small .icon {
    margin-top: 10px;
}

.icon-box.box-type {
    background: #fafafa;
    border: 1px solid #ececec;
    border-radius: 4px;
    padding: 20px;
}

.icon-box.box-type .icon {
    margin-top: 20px;
}

.v-slider-frame {
    height: 50px;
    overflow: hidden;
    text-align: center;
}

ul.v-slides {
    list-style-type: none;
    transform: translateY(50px);
    padding: 0;
}

.v-slide {
    font-size: 30px;
    line-height: 50px;
    color: #CC1100;
}

/** ios caret problem */
input {
    caret-color: #333333;
}

textarea {
    caret-color: #333333;
}

[data-pnotify].pnotify.stack-bar-bottom {
    width: 100%;
    border-radius: 0;
}

.img-report {
    width: 100%;
}

@media (max-width: 768px) {
    .img-report {
        width: 50%;
    }
}

.icon-no-mg::before {
    margin: 0;
}


/* checkbox-rect */
.checkbox-rect input[type="checkbox"] {
    display: none;
}

.checkbox-rect input[type="checkbox"] + label {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 20px;
    font: 14px/20px "Open Sans", Arial, sans-serif;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

.checkbox-rect input[type="checkbox"]:hover + label:hover {
    /*color: rgb(23, 86, 228);*/
}

.checkbox-rect input[type="checkbox"]:hover + label:before {
    /*background: #50565a;*/
    box-shadow: inset 0 0 0 2px #f7f2f2;
}

.checkbox-rect input[type="checkbox"] + label:last-child {
    margin-bottom: 0;
}

.checkbox-rect input[type="checkbox"] + label:before {
    content: "";
    font-family: "FontAwesome";
    display: block;
    width: 1.4em;
    height: 1.4em;
    border: 1px solid #343a3f;
    border-radius: 0.2em;
    position: absolute;
    left: 0;
    top: 0;
    -webkit-transition: all 0.2s, background 0.2s ease-in-out;
    transition: all 0.2s, background 0.2s ease-in-out;
    background: #f3f3f3;
}

.checkbox-rect input[type="checkbox"]:checked + label:before {
    width: 1.3em;
    height: 1.3em;
    border-radius: 0.2em;
    border: 2px solid #fff;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    background: #cc1100;
    box-shadow: 0 0 0 1px #000;
}


/********** checkbox *************/
.checkbox-css input {
    opacity: 0;
    position: absolute;
    width: 30px;
    height: 30px;
}

.checkbox-css label {
    position: relative;
    padding: 0 0 0 40px;
    height: 30px;
    margin: 0;
    line-height: 30px;
    font-weight: bold;
}

.checkbox-css label:before,
.checkbox-css label:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    transition: border-width 0.3s 0s ease;
    border-radius: 50%;
}

.checkbox-css label:before {
    border: 2px solid #FFFFFF;
    background: transparent;
    width: 30px;
    height: 30px;
}

.checkbox-css label:after {
    width: 22px;
    height: 22px;
    margin: 4px;
    border: 11px solid #FFF;
    background: transparent;
}

.checkbox-css input:checked + label:after {
    border-width: 5px;
}

.checkbox-css input:checked + label:before {
    background-size: cover;
    background-origin: border-box;
}

.checkbox-css label:before {
    border-radius: 0 0;
    border-width: 2px;
}

.checkbox-css label:after {
    border-radius: 0 0;
    border-width: 0 22px 0 0;
    /* to enable faux tick draw animation effect */
    margin: 4px;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'%3E%3Cpath fill='%23fff' d='M0 0v32h32V0H0zm24 9l2 2-12 12-6-6 2-2 4 4z'/%3E%3C/svg%3E");
    background-size: cover;
    background-origin: border-box;
}

.checkbox-css input:checked + label:after {
    border-width: 0;
}
.alert.alert-green {
    background-color: #66cc66;
    border-color: #66cc66;
}

.swal2-input, .swal2-file, .swal2-textarea {
    font-size: 14px;
}

/* Chrome, Safari, Edge, Opera */
.cart-input input::-webkit-outer-spin-button, .cart-input input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.cart-input input[type=number] {
    -moz-appearance: textfield;
}

/*
!* ensures the increment/decrement arrows always display *!
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    opacity: 1;
}*/

/*loader spinner*/

.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
}

.lds-ellipsis div {
    position: absolute;
    top: 10px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #fdd;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes lds-ellipsis3 {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(0);
    }
}

@keyframes lds-ellipsis2 {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(24px, 0);
    }
}


.password-container {
    position: relative;
}

.password-container input[type="password"],
.password-container input[type="text"] {
    width: 100%;
    padding: 12px 36px 12px 12px;
    box-sizing: border-box;
}

#togglePassword, #toggleConfirmPassword, #togglePasswordModal {
    position: absolute;
    top: 28%;
    right: 4%;
    cursor: pointer;
    color: lightgray;
}

.loader-pulse {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    display: inline-block;
    margin: 0 30px;
    position: relative;
    background: #FFF;
    box-shadow: -24px 0 #FFF, 24px 0 #FFF;
    box-sizing: border-box;
    animation: shadowPulse 2s linear infinite;
}

@keyframes shadowPulse {
    33% {
        background: #FFF;
        box-shadow: -24px 0 #cc1100, 24px 0 #FFF;
    }
    66% {
        background: #cc1100;
        box-shadow: -24px 0 #FFF, 24px 0 #FFF;
    }
    100% {
        background: #FFF;
        box-shadow: -24px 0 #FFF, 24px 0 #cc1100;
    }
}
